<ng-container *ngIf="loginDisplay">
<app-page-header *ngIf = "hideElement"></app-page-header>
<div [style.z-index]="privacyPopupOpened ? '0' : '12000'" *ngIf="isGlobalSpinnerVisible" class="spiner-container">
  <app-mya-spinner></app-mya-spinner>
</div>

<div>
  <router-outlet></router-outlet>
</div>

<!-- Footer is moved from page content componnet to here  -->
<app-page-footer ></app-page-footer>
</ng-container>
