import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MyaConfirmationPopupComponent } from 'src/app/mya-controls/mya-confirmation-popup/mya-confirmation-popup.component';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageFooterComponent implements OnInit {
  public actualYear = new Date().getFullYear();
  public myAVersion = '0.0.1';
  constructor(private myaConfirmationDialog: MatDialog) { }

  ngOnInit(): void {
      //todo
      console.log();
  }

  privacyStatementClicked(){
    let dialogRef = this.myaConfirmationDialog.open(MyaConfirmationPopupComponent, {
      height: '418px',
      width: '650px',
      disableClose: true,
      closeOnNavigation: false
    });
    dialogRef.componentInstance.displayPrivacyStatementPopup = true;
  }

  termsOfUseClicked(){
    let dialogRef = this.myaConfirmationDialog.open(MyaConfirmationPopupComponent, {
      height: '418px',
      width: '650px',
      disableClose: true,
      closeOnNavigation: false
    });
    dialogRef.componentInstance.displayTermsOfUsePopup = true;
  }
}
