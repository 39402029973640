import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpClient, HttpBackend } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, mergeMap, materialize, dematerialize} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

  constructor(private http: HttpClient, private handler: HttpBackend) {
    console.log('MyApproval is in Mock Mode!');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // ExpenseList
      if (environment.name !== 'Mock') return next.handle(request);
      this.http = new HttpClient(this.handler);
      return of(null).pipe(mergeMap(() => {
      if (request.url.includes('/getExpenses') && request.method === 'GET') {
        return this.http.get('assets/data/expenseList.json').pipe(
          map(expenseJson =>
            new HttpResponse({
              status: 200,
              body: new Blob([JSON.stringify(expenseJson)],
                { type: 'application/json' })})
           ));
      }
      if (request.url.includes('/payableExpenseMetadata') && request.method === 'GET') {
        return this.http.get('assets/data/approveeList.json').pipe(
          map(ApproveeJson =>
            new HttpResponse({
              status: 200,
              body: new Blob([JSON.stringify(ApproveeJson)],
                { type: 'application/json' })})
           ));
      }
      if (request.url.includes('/coverageCountry') && request.method === 'GET') {
        return this.http.get('assets/data/countryList.json').pipe(
          map(ApproveeJson =>
            new HttpResponse({
              status: 200,
              body: new Blob([JSON.stringify(ApproveeJson)],
                { type: 'application/json' })})
           ));
      }
      if (request.url.includes('/historicalExpenses') && request.method === 'GET') {
        return this.http.get('assets/data/historicalExpenseList.json').pipe(
          map(ApproveeJson =>
            new HttpResponse({
              status: 200,
              body: new Blob([JSON.stringify(ApproveeJson)],
                { type: 'application/json' })})
           ));
      }
      if (request.url.includes('/expenseDetails') && request.method === 'GET') {
        return this.http.get('assets/data/expenseDetailInfo.json').pipe(
          map(ApproveeJson =>
            new HttpResponse({
              status: 200,
              body: new Blob([JSON.stringify(ApproveeJson)],
                { type: 'application/json' })})
           ));
      }
      if (request.url.includes('/getUserRole') && request.method === 'GET') {
        return this.http.get('assets/data/user.json').pipe(
          map(ApproveeJson =>
            new HttpResponse({
              status: 200,
              body: new Blob([JSON.stringify(ApproveeJson)],
                { type: 'application/json' })})
           ));
      }
      if (request.url.includes('/rejectReasons') && request.method === 'GET') {
        return this.http.get('assets/data/rejectReasons.json').pipe(
          map(ApproveeJson =>
            new HttpResponse({
              status: 200,
              body: new Blob([JSON.stringify(ApproveeJson)],
                { type: 'application/json' })})
           ));
      }
       return next.handle(request);
     }));
    //   .pipe(materialize())
    //   .pipe(dematerialize());
  }
}

function ok(body: object): HttpResponse<any> {
  return new HttpResponse({ status: 200, body });
}

export let fakeBackendProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
