import { Injectable, Inject } from '@angular/core';
import { IAuthService } from './Iauth.service';
import User from '../../../shared/models/user';
import { Msal2Service } from '../login/msal2.service';
import { EventBusService, MyApprovalEvents } from '../../services/event-bus/event-bus.service';
import { MyaGlobalCacheService } from '../cache/mya-global-cache.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  approveeEid: string = '';
  loginUser: User = null;
  constructor(@Inject('AuthService') private authservice: IAuthService, private eventbus: EventBusService, private myaGlobalCacheService: MyaGlobalCacheService) {
    this.eventbus.on(MyApprovalEvents.ApproveeChanged)
    .subscribe(res => {
      if(res.value){
        this.approveeEid = res.value.approvee == undefined ? res.value : res.value.approvee
      }
    });
   }
  
   public isAnyPayable(): boolean{
    let userRole = this.myaGlobalCacheService.getGlobalCache<string>('UserRole') ?? '';
    this.loginUser.IsPayableTeamMember = this._IsPayableTeamMember(userRole);
    this.loginUser.IsAFSPayableTeamMember = this._IsAFSPayableTeamMember(userRole);
    this.loginUser.IsMDPayableTeamMember = this._IsMDPayableTeamMember(userRole);
    this.loginUser.IsAuditLeadPayableTeamMember = this._IsAuditorLeadPayableTeamMember(userRole);
    this.loginUser.isVipAuditorRole = this._isVipAuditorRole(userRole);
    return this.loginUser.IsPayableTeamMember || this.loginUser.IsAFSPayableTeamMember || this.loginUser.IsMDPayableTeamMember || this.loginUser.IsAuditLeadPayableTeamMember || this.loginUser.isVipAuditorRole
   }

   private  _IsPayableTeamMember(userRole : string): boolean{
     return userRole == "TEAuditReviewerAFS" || userRole == "TEAuditReviewerMD" || userRole == "TEAuditReviewerNonMD" 
     || userRole == "AuditLead" || userRole == "VipAuditor" 
   }

   private  _IsAFSPayableTeamMember(userRole : string): boolean{
    return userRole == "TEAuditReviewerAFS"
  }

  private  _IsMDPayableTeamMember(userRole : string): boolean{
    return userRole == "TEAuditReviewerMD"
  }

  private  _IsAuditorLeadPayableTeamMember(userRole : string): boolean{
    return userRole == "AuditLead"
  }

  private  _isVipAuditorRole(userRole : string): boolean{
    return userRole == "VipAuditor"
  }
   
  public getLoginUser(): User {
    if (!this.loginUser) {
      let profile = this.authservice instanceof Msal2Service ? this.authservice.getActiveUser() : this.authservice.identityClaims;
      this.loginUser = new User(profile);
    }
    return this.loginUser;
  }
}
