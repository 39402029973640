import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupTemplateComponent } from './mya-popup/popup-template.component';
import { MaterialModule } from './material/material.module';
import { ClickOutsideDirective } from '../shared/directives/click-outside.directive';
import { ClickInsideDirective } from '../shared/directives/click-inside/click-inside.directive';
import { MyaSpinnerComponent } from './mya-spinner/mya-spinner.component';
import { ToastrModule } from 'ngx-toastr';
import { MyaDropdownComponent } from './mya-dropdown/mya-dropdown.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MyaConfirmationPopupComponent } from './mya-confirmation-popup/mya-confirmation-popup.component';
import { FooterTermsStatementComponent } from './mya-confirmation-popup/footer-terms-statement/footer-terms-statement.component';
import { ResizeTextareaDirective } from '../shared/directives/dynamic-textarea/dynamic-textarea.directive';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    ClickInsideDirective,
    MyaSpinnerComponent,
    MyaDropdownComponent,
    MyaConfirmationPopupComponent,
    FooterTermsStatementComponent,
    ResizeTextareaDirective
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    MaterialModule,
    ToastrModule.forRoot(),
    FontAwesomeModule,
  ],
  exports: [
    A11yModule,
    ClickOutsideDirective,
    ClickInsideDirective,
    MaterialModule,
    MyaSpinnerComponent,
    MyaDropdownComponent,
    FontAwesomeModule,
    ResizeTextareaDirective
  ]
})
export class MyAControlsModule {
  constructor(library:FaIconLibrary){
    library.addIconPacks(fas, far);
  }
 }
