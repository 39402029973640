<h2 class="popup-title" mat-dialog-title></h2>

<mat-dialog-content class="mat-typography" #content>
  <div class="popup-content">
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="popup-close" mat-button
    [mat-dialog-close]="!displayTermsOfUsePopup?'privacy-statement-closed':'termsOfUse-Statement-closed'">Close</button>
</mat-dialog-actions>
