import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { UserService } from '../../../core/services/user/user.service';
import { MyaGlobalCacheService } from '../../../core/services/cache/mya-global-cache.service'
import { IAuthService } from 'src/app/core/services/user/Iauth.service';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationStart, Event as NavigationEvent} from '@angular/router';
import { ProfilePicture } from '../../../shared/models/people-picker';
import { PeoplePickerService } from '../../../mya-approvee/services/people-picker.service';
import { filter } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config/app-config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.sass']
})
export class PageHeaderComponent implements OnInit {
  loginUserProfilePicture: any;
  private appConfigData: any;
  public environment = environment.name;

  constructor(
    private myaGlobalCacheService: MyaGlobalCacheService, @Inject('AuthService')
    private authService: IAuthService, private router:Router,
    private userService: UserService, private peoplePickerService: PeoplePickerService, private appConfigService: AppConfigService) {
    this.appConfigData = this.appConfigService.getAppConfig;
  }

  ngOnInit(): void {
    this.loginUserProfilePicture = "assets/image/myte.jpg";
    let loginUserEid = this.userService.getLoginUser().enterpriseId;
    this.peoplePickerService.getUserProfile(loginUserEid)
      .pipe(filter(val=> val !== null))
      .subscribe(res => {
        this.loginUserProfilePicture = res.data;
    });
  }

  backToHome(){
    this.router.navigate(['/expense']);
  }
  logOut() {
    this.myaGlobalCacheService.flushStorage();
    this.authService.logout();
  }

  //Method to open the MyteClassic in a new page
  onHomeIconClick() {
    const url = this.appConfigData.MyteNextUrl;
    window.open(url);
  }
}
