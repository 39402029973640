<div *ngIf="!displayPrivacyStatementPopup && !displayTermsOfUsePopup">
  <h1 mat-dialog-title>{{data.Title}}</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="my-4">
    <mat-dialog-content>
      <mat-label> {{data.Description}}</mat-label>
    </mat-dialog-content>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-button mat-dialog-close class="closeBtn mx-2 btn btn-light">Cancel</button>
    <button mat-button cdkFocusInitial [mat-dialog-close]="true" class="okBtn mx-2 btn btn-light">Ok</button>
  </div>
</div>

<app-footer-terms-statement *ngIf="displayPrivacyStatementPopup || displayTermsOfUsePopup" [displayTermsOfUsePopup]="displayTermsOfUsePopup"></app-footer-terms-statement>
