<ng-container *ngIf="peoplePickerResult">
  <div
    *ngFor="let user of allpeoplePickerResult | filterApprovee : setFilteredCountryKey; let i = index; trackBy:trackFunc"
    (click)="selectUser(user)" class="custom">
    <div class="userCard div" [ngSwitch]="user.enterpriseId"
      [style] = "user.enterpriseId == currentSelectedUser ? peopleSelectedColor : ''"
      [ClickInside]="'color: white; background-color: rgb(117, 0, 192) !important;'">
      <img [src]="user?.profilePicture?.data" alt="Profile Picture" class="img userProfile">
      <div class="col-xs-9 col-sm-8 userInfo">
        <div class="col-11">
          <span class="enterpriseId">{{user.enterpriseId}}</span>
          <div class="row"><span>{{user.employeeLevel}}</span></div>
          <div class="row"><span>{{user.company}} - {{user.country}} - CC{{user.companyInformation?.code}}</span></div>
          <div class="row"><span class="reviewTimeReport" (click)="openReviewLink(user)">Review Time Report</span></div>
        </div>
        <div class="col-1">
          <span class="expenseCount">{{this.isDataCapture ? user.expenseCountDataCapture : user.expenseCount}}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>