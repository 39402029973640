import { Pipe, PipeTransform } from '@angular/core';
import { PeoplePicker } from 'src/app/shared/models/people-picker';

@Pipe({
  name: 'filterApprovee'
})

export class FilterApproveePipe implements PipeTransform {

  public transform(allpeoplePickerResult: PeoplePicker[], selectedCountry: string[]): PeoplePicker[] {

    if (selectedCountry && allpeoplePickerResult && selectedCountry.length) {
      return allpeoplePickerResult.filter(p => selectedCountry.indexOf(p.country) !== -1);
    }
    return allpeoplePickerResult;
  }
}
