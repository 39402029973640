import { Injectable } from '@angular/core';
import { BaseCacheService, IStorageCache, IObservableCache } from './base-cache.service';
import { Observable, Subject, observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyaGlobalCacheService extends BaseCacheService {


  handleHttpResponse<T>(key: string, value: Observable<T>): Observable<T> {
    let cacheData = this.keyExists<T>(key);
    if (cacheData != undefined)
      return cacheData;
    else
      return this.setHttpResponse(key, value).dataValue;
  }

  clearCacheByKey(key: string) : void {
    super.clearCacheByKey(key);
  }

  flushCache(): void{ 
    this.clearAllCache();
  }

  saveGlobalCache(key: string, value: string): void {
    let dataValue : IStorageCache<string>  = {
      data : value,
      expireDatetime : new Date(new Date().getTime() + (30 * 60000))
    }
    this.saveInLocalStorage(key, dataValue);
  }

  getGlobalCache<T>(key: string): T | null{
    let dataInCache =  this.isInLocalStorage<T>(key);
    return dataInCache == null ? null : dataInCache.data;
  }

  flushStorage(){
    localStorage.clear();
  }

  getCacheByKey(key: string):  Observable<any> {
    return this.keyExists(key);
  }

}
