import { MyaGlobalCacheService } from './../../core/services/cache/mya-global-cache.service';
import { PeoplePickerResult, TimeReportClient } from 'src/app/core/services/clientapi/timereport-client';
import { Injectable, APP_INITIALIZER } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { PayableExpenseMetadataDto } from 'src/app/core/services/clientapi/expenseapproval-client';
import { defaultProfilePicture, PeoplePicker, IPeopleSearchResult, ProfilePicture } from 'src/app/shared/models/people-picker';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable()
export class PeoplePickerService {
  private _peopleList: BehaviorSubject<PeoplePicker[]>;
  private dataStore: {
    selectedPeople: PeoplePicker[];
  };

  public currentSeletedPeople(enterpriseId: string): PeoplePicker{
    return this.dataStore.selectedPeople.filter(p=> p.enterpriseId == enterpriseId)[0];
  }

  constructor(private timeReportApi: TimeReportClient, private myaGlobalCacheService: MyaGlobalCacheService, private domSanitizer: DomSanitizer) {
    this.dataStore = { selectedPeople: [] };
    this._peopleList = new BehaviorSubject<PeoplePicker[]>([]);
  }

  peoplePiker_initializer(value: any) {
    this.dataStore.selectedPeople = this.mapToPeoplePicker(value);
    this._peopleList.next(Object.assign({}, this.dataStore).selectedPeople);
  }

  get getPeoplePickerList(): Observable<PeoplePicker[]> {
    return this._peopleList.asObservable();
  }

  sortPeople(enterpriseId: string) {
    let people = this.dataStore.selectedPeople.find(p => p.enterpriseId == enterpriseId);
    this.addPeople(people);
    this.dataStore.selectedPeople = Array.from(new Set(this.dataStore.selectedPeople));
    this._peopleList.next(Object.assign({}, this.dataStore).selectedPeople);
    return of(null);
  }

  addPeople(people: PeoplePicker) {
    this.dataStore.selectedPeople.unshift(people);
    this._peopleList.next(Object.assign({}, this.dataStore).selectedPeople);
    return of(null);
  }

  removePeople(people: PeoplePicker) {
    this.dataStore.selectedPeople = this.dataStore.selectedPeople.filter(
      p => p.enterpriseId != people.enterpriseId
    );
    this._peopleList.next(Object.assign({}, this.dataStore).selectedPeople);
    return of(null);
  }

  updatePeopleList(people: PeoplePicker, isDataCapture: boolean) {
    this.dataStore.selectedPeople = this.dataStore.selectedPeople.map((p: PeoplePicker) => {
      if (p.enterpriseId === people.enterpriseId) {
        p = people;
      }
      return p;
    })
    let expenseCount = isDataCapture ? people.expenseCountDataCapture : people.expenseCount;
    if (expenseCount == 0)
    this.removePeople(people)
    this._peopleList.next(Object.assign({}, this.dataStore).selectedPeople);
    return of(null);
  }
  mapToPeoplePicker(approveeList: PayableExpenseMetadataDto[]): PeoplePicker[] {
    let peoplepicklist: PeoplePicker[] = [];
    approveeList.forEach(a => {
      let peoplePicker = new PeoplePicker();
      peoplePicker.countryKey = a.countryKey;
      peoplePicker.currentPeriod =a.currentPeriod;
      peoplePicker.enterpriseId = a.approvee;
      peoplePicker.country = a.country;
      peoplePicker.employeeLevel = a.employeeLevel;
      peoplePicker.expenseCount = a.expenseCount;
      peoplePicker.company = a.company;
      peoplePicker.companyInformation = a.companyInformation;
      peoplePicker.expenseCountDataCapture = a.expenseCountDataCapture;
      peoplePicker.profilePicture = this.mapProfilePicture(null, null);
      peoplepicklist.push(peoplePicker);
    });
    return peoplepicklist;
  }

  public getUserProfile(enterpriseId: string): Observable<ProfilePicture> {
    var secret = ""

    var i = 0,
    oJson : any = {},
    sKey;
    for (; sKey = window.localStorage.key(i); i++) {
      if(sKey.includes("login.windows.net-idtoken"))
      oJson[sKey] = window.localStorage.getItem(sKey);
    }

    if(oJson[Object.keys(oJson)[0]] != undefined) {
      var a = JSON.parse(oJson[Object.keys(oJson)[0]])
      secret = a.secret
    }

    let profilePictureHttpRequest = this.timeReportApi.getProfilePicture(enterpriseId, undefined, secret, "MyApprovals")
      .pipe(map(profile => {
        return this.mapProfilePicture(profile, enterpriseId)
      })).pipe(catchError(err => {
        return of(null);
      }));
    return this.myaGlobalCacheService.handleHttpResponse("peoplePicker" + enterpriseId, profilePictureHttpRequest);
  }

  private sanitize(data: string): SafeUrl {
    if (!data) data = defaultProfilePicture.data;
    return this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,'.concat(data));
  }

  private mapProfilePicture(profilePicture: any, enterpriseId: string) {
    if (!profilePicture) return new ProfilePicture(null, enterpriseId, this.sanitize(null));
    return new ProfilePicture(profilePicture.peopleKey, profilePicture.entId, this.sanitize(profilePicture.data));
  }

  public mapPeopleSearchData(peoplePickerList: PeoplePickerResult[]): IPeopleSearchResult[]
  {
    let peopleSearchDataList : IPeopleSearchResult[] = [];
    peoplePickerList.forEach(peoplePicker => {
      let peopleSearchData : IPeopleSearchResult = {
        enterpriseId : peoplePicker.enterpriseId,
        xWorkforceCareerLevelDescription: peoplePicker.xWorkforceCareerLevelDescription,
        profilePictureData: undefined
      };
      peopleSearchDataList.push(peopleSearchData);
    })
    return peopleSearchDataList;
  }
}
