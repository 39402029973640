import { environment } from 'src/environments/environment';

export default class User {
    public peopleKey: number;
    public enterpriseId: string;
    public IsPayableTeamMember : boolean;
    public IsAFSPayableTeamMember : boolean;
    public IsMDPayableTeamMember: boolean;
    public IsAuditLeadPayableTeamMember: boolean;
    public isVipAuditorRole:boolean;
    public countryKey: string;
    constructor(userProfile: any){
        if (userProfile)
        {
            if (!environment.production){
                this.enterpriseId = userProfile.eid;
                this.peopleKey = +userProfile.peoplekey ? +userProfile.peoplekey : +userProfile.idTokenClaims.peopleKey;
            }
            else {
                let userNameClaim = String(userProfile.username);
                this.enterpriseId = userNameClaim.split('@')[ 0 ].toLowerCase();
                this.peopleKey = +userProfile.peoplekey ? +userProfile.peoplekey : +userProfile.idTokenClaims.peopleKey;
            }

        }else {
            throw new Error('Oauth Error: No claims for user');
        }
    }
}