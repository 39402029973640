import { Injectable } from '@angular/core';
import { IAuthService } from '../user/Iauth.service';
import { MsalService } from '@azure/msal-angular';
import { config } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class Msal2Service implements Partial<IAuthService> {

    constructor(private msalService: MsalService){
        console.log('msal startup')
    }

    get identityClaims(): any { 
       return this.msalService.instance.getAllAccounts().length > 0;
    }

    setActiveUser(): void {
        let accounts = this.msalService.instance.getAllAccounts();
        if (accounts.length > 0)
            this.msalService.instance.setActiveAccount(accounts[0]);
        else
            console.error("login failed");
    }

    getActiveUser(): any{
        return this.msalService.instance.getAllAccounts()[0];
    }

    logout(): void {
        this.msalService.logout();
    }
}