import { IconProp } from "@fortawesome/fontawesome-svg-core";

export class ReceiptDetailConstants {
    static iconClass: Record<string, IconProp> = {
        'doc': {prefix:"far",iconName:"file-word"},
        'docx': {prefix:"far",iconName:"file-word"},
        'xls': {prefix:"far",iconName:"file-excel"},
        'xlsx': {prefix:"far",iconName:"file-excel"},
        'pdf': {prefix:"far",iconName:"file-pdf"},
        'msg': {prefix:"far",iconName:"envelope"},
        'defaultIconClass': {prefix:"far",iconName:"file"}
    }
    static imageExtension: string[] = ['png', 'jpg', 'tif', 'jpeg', 'tiff', 'bmp', 'gif'];
}

export class NotificationConstants {
    static mediumTypes: Record<string, any> = {
        'Email': { IconName: "envelope", HeaderTitle: "You'll receive required approval emails:" },
        'Mobile': { IconName: "mobile-screen-button", HeaderTitle: "You'll receive required approval mobile alerts:" }
    }
}

export const ToastMessageConstants = {
     ErrorType : 'Error',
     Success : 'Success',
     Info : 'Info',
     Warning : 'Warning',
     RejectSuccess : 'The expense was rejected successfully.',
     RejectError : 'Unable to reject the expense',
     RouteSuccess : 'The expense was routed successfully.',
     RouteError : 'Unable to route the expense. Please try again later',
     ApproveSuccess : 'The expense was approved successfully.',
     ApproveError : 'Unable to approve the expense. Please try again later',
     SaveTheDataSuccess : 'The data was saved successfully',
     SaveTheDataError : 'Unable to save the data. Please try again later',
     SendCommentsSuccess : 'The comment was sent successfully.',
     SendCommentsError : 'Unable to send comments. Please try again later',
     GetCoverageCoutriesError : 'Unable to retrieve your countries/regions. Please try again later',
     NoApprovees : 'There are no approvees found',
     GetMetadataError : 'Unable to get metadata of expense. Please try again later',
     NoAccess : 'Sorry, You do not have access to EndUser',
     GroupFilter : 'Required information for the Payable Group filter',
     ErrorMessage : 'An error has occured. Please try again later'

}

//Constant for expense type to material icon mapping
export const ExpenseTypes: Record<string, string> = {
    'EX01': 'local_taxi',
    'EX02': 'directions_car',
    'EX03': 'business',
    'EX04': 'local_dining',
    'EX05': 'local_gas_station',
    'EX06': 'directions_bus',
    'EX07': 'attach_money',
    'EX08': 'flight_takeoff',
    'EX09': 'directions_railway',
    'EX10': 'attach_money',
    'EX11': 'attach_money',
    'EX12': 'phone',
    'EX13': 'business',
    'EX14': 'public',
    'EX15': 'attach_money',
    'EX16': 'hotel',
    'EX18': 'local_parking',
    'EX19': 'next_week',
    'EX21': 'public'
}
//Default icon for expense
export const defaultExpenseIcon: string = 'attach_money';
export const NotificationControllerName: string = 'Notification';
export const ExpenseApprovalControllerName: string = 'ExpenseApproval';