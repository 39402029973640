<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav class="app-sidenav" mode="side" opened disableClose="true">
    <div>
      <div class="search"  (clickOutside)="clearInput()" *ngIf="showSearchdiv">
        <mat-form-field class="matformfield">
          <mat-label class="matlabellooking">
            <fa-icon style="margin-right: 5px;" [icon]="faSearch"></fa-icon>Looking for someone...?
          </mat-label>
          <input matInput #input [ngbTypeahead]="search" [resultFormatter]="formatter" [inputFormatter]="inputFormatter"
            [matAutocomplete]="PeoplePickerauto" maxlength="50" (input)="filterInput($event)">
          <button *ngIf="input.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearInput()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete #PeoplePickerauto="matAutocomplete"
            (optionSelected)="onPeopleSelected($event.option.value)">
            <mat-option *ngFor="let people of peopleSearchResult" [value]="people.enterpriseId"
              class="peopleSelectStyle">
              <img [src]="people?.profilePictureData" class="userProfile">
              <div class="col-xs-9 col-sm-8 userInfo">
                <div class="col-11">
                  <div class="row" style="margin-left:20px">
                    <span style="height: 15px">{{people.enterpriseId}}</span>
                    <span style="font-style: italic">{{people.xWorkforceCareerLevelDescription}}</span>
                  </div>
                </div>
              </div>
            </mat-option>
            <mat-option *ngIf="showSearchSpinner" style="text-align: center;">
              <fa-icon icon="spinner" [pulse]="true" size="1x"></fa-icon>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div style="margin-top: 5px;">
      <mat-radio-group color="accent" aria-label="Select an option">
        <mat-radio-button color="primary" value="1" class="col-5 mat-radio-button" checked
          (change)=RadioButtonChange(false) style="transform: scale(0.9)">Requires Review</mat-radio-button>
        <mat-radio-button color="primary" value="2" class="col-6" (change)=RadioButtonChange(true);
          style="transform: scale(0.9);margin-left: 9px;">Requires Data Entry
        </mat-radio-button>
      </mat-radio-group>
      </div>
      <div class="search"  (clickOutside)="hideOptions($event)">
        <mat-form-field class="matformfield" appearance="fill">
          <mat-label class="matlabel">Select a Country/Region ...
            <span *ngIf="countryInput.value || selectedCountries.length !== 0" class="clearall"
              (click)="clearAllCountries()">Clear All</span>
          </mat-label>
          <mat-chip-grid #chipGrid aria-label="countrySelection">
              <mat-chip-row *ngFor="let country of selectedCountries" (removed)="removeCountry(country)" class="matchip">
                {{country}}
                <button matChipRemove [attr.aria-label]="'remove ' + country"> 
                  <fa-icon matChipRemove icon="xmark"></fa-icon>
                </button>
              </mat-chip-row>
              <input matInput placeholder="Search..." type="text" #countryInput [formControl]="myControl"
            [matChipInputFor]="chipGrid"
            (click)="showdiv?showdiv=false:showdiv=true" 
            (keyup)="showdiv=true"
            maxlength="26"
            (keyup.enter)="hideOptions($event)"/>
          </mat-chip-grid>
        </mat-form-field>
        <div  #dropdown [hidden]="!showdiv" class="dropdown">
          <div class="dropdownlist">
            <div *ngFor="let country of countryOptions | async; trackBy: trackFunc" (click)="optionSelected(country)"
              [class.listOptionsChecked]="this.selectedCountries.includes(country)">
              {{country}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showSpinner" class="spinner-container">
      <app-mya-spinner></app-mya-spinner>
    </div>
    <div class="approveeListcontent scrollbar">
      <app-approvee-list #approveeListComponent *ngIf="currentSelectedApprovee" #approveeListControl
        [currentSelectedUser]="currentSelectedApprovee" [isDataCapture]="this.isDataCapture"
        [setFilteredCountryKey]="selectedCountries" (lookingForNewUserEvent)="lookingForNewUser()" (currentSelectedUserChangedEvent)="currentSelectedUserChanged($event)">
      </app-approvee-list>
    </div>

  </mat-sidenav>
  <mat-sidenav-content class="app-sidecontent">
    <div class="route-outlet" *ngIf="this.approveeList && this.approveeList.length > 0">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>