import { Injectable } from '@angular/core';
import { TimeReportClient, PeoplePickerResult } from 'src/app/core/services/clientapi/timereport-client';
import { ExpenseApprovalClient, PayableExpenseMetadataDto, UserCoverageCountry } from 'src/app/core/services/clientapi/expenseapproval-client';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable, forkJoin, of, Observer, observable, ValueFromArray } from 'rxjs';
import { MyaGlobalCacheService } from '../../core/services/cache/mya-global-cache.service';
import { UserService } from '../../core/services/user/user.service';
import { LogService } from '../../core/services/log/log.service';
import { ToastMessageConstants } from 'src/app/shared/contants/constants';
@Injectable()
export class ApproveesService {

  constructor(private expenseApprovalApi: ExpenseApprovalClient, private timeReportApi: TimeReportClient, private myaGlobalCacheService: MyaGlobalCacheService, private userService: UserService,private logService:LogService) { }
  public getCoverageCountry(): Observable<UserCoverageCountry[]> {
    let loginUserEid = this.userService.getLoginUser().enterpriseId;
let coverageCountryHttpClient = this.expenseApprovalApi.getCoverageCountry(loginUserEid)
    .pipe(tap(countries => {
      return countries;
    }))
    .pipe(catchError(err => {
      this.logService.showError(ToastMessageConstants.GetCoverageCoutriesError, ToastMessageConstants.ErrorType);
      return of(null);
    }));
    return this.myaGlobalCacheService.handleHttpResponse("CoverageCountry" + loginUserEid, coverageCountryHttpClient);
  }

  public getPayableExpenseMetadata(group: string, isDataCapture: boolean ): Observable<PayableExpenseMetadataDto[]> {
   
    let loginUserEid = this.userService.getLoginUser().enterpriseId;
    let shortNmGroup = this.mapGroupByUserRoleName(group);
    let approveeHttpclient= this.expenseApprovalApi.getPayableExpenseMetadata(loginUserEid, shortNmGroup, null, isDataCapture)
    .pipe(tap((approvee: PayableExpenseMetadataDto[]) => {
      if(approvee.length == 0 )
        this.logService.showInfo(ToastMessageConstants.NoApprovees, ToastMessageConstants.Info)
      return approvee;
    }))
    .pipe(catchError(err => {
      if (err.response.includes(ToastMessageConstants.GroupFilter))
        this.logService.showWarning(ToastMessageConstants.NoAccess, ToastMessageConstants.Warning)
      else
        this.logService.showError(ToastMessageConstants.GetMetadataError, ToastMessageConstants.ErrorType);
      return of(null);
    }));
    return this.myaGlobalCacheService.handleHttpResponse("PayableExpenseMetadata" + loginUserEid + group + isDataCapture.toString(), approveeHttpclient);
  }

  public getUserPayableExpenseMetadata(enterpriseId: string, group: string,  isDataCapture: boolean ): Observable<PayableExpenseMetadataDto[]> {
    let loginUserEid = this.userService.getLoginUser().enterpriseId;
    let shortNmGroup = this.mapGroupByUserRoleName(group);
    let approveeHttpclient= this.expenseApprovalApi.getPayableExpenseMetadata(loginUserEid, shortNmGroup, enterpriseId, isDataCapture)
    .pipe(tap(approvee => {
      return approvee;
    }))
    .pipe(catchError(err => {
      this.logService.showError(ToastMessageConstants.GetMetadataError, ToastMessageConstants.ErrorType);
      return of(null);
    }));
    return approveeHttpclient;
  }

  private mapGroupByUserRoleName(userrole: string): string {
    let group = '';
    if ((userrole as string).includes('NonMD')) {
      group = 'NONMD';
    } else if ((userrole as string).includes('MD')|| (userrole as string ).includes('AuditLead') || (userrole as string ).includes('Vip')) {
      group = 'MD';
    } else if ((userrole as string).includes('AFS')) {
      group = 'AFS';
    }
    return group;
  }
  public getUserRole(): Observable<string> {
    let loginUserEid = this.userService.getLoginUser().enterpriseId;
    let userRoleHttpClient =  this.timeReportApi.getUserRole(loginUserEid, null, null, null)
    .pipe(tap(role => {
      return role;
    }))
    .pipe(catchError(err => {
      this.logService.showError(ToastMessageConstants.ErrorMessage, ToastMessageConstants.ErrorType);
      return of(null);
    }));
    return this.myaGlobalCacheService.handleHttpResponse("UserRole" + loginUserEid, userRoleHttpClient);
  }

  public peoplePickerSearch(term: string): Observable<PeoplePickerResult[]> {
    let peopleKey = this.userService.getLoginUser().peopleKey;
    let loginUserEid = this.userService.getLoginUser().enterpriseId;
    let PeoplePickerResult = this.timeReportApi.getPeoplePicker(term, loginUserEid, 'ExpenseApprover', undefined, undefined, [])
    .pipe(tap(peoplePicker=>{
      return peoplePicker;
    })).pipe(catchError(err => {
      this.logService.showError(ToastMessageConstants.ErrorMessage, ToastMessageConstants.ErrorType);
      return of(null);
    }));
    return PeoplePickerResult;
  }

}
