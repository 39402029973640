import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Subscription, Observable, BehaviorSubject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  private subject = new BehaviorSubject<any>('');
  constructor() { }

  on(selectedEvent: MyApprovalEvents): Observable<any> {
    return this.subject.pipe(
      filter((e: EmitEvent) => {
        return e.name === selectedEvent;
      }),
      map((event: EmitEvent) => {
        console.log(`subscribed event ${selectedEvent}`);
        return event;
      })
    )
  }

  dispatch(event: EmitEvent): void {
    console.log(`Dispatch event ${event.name}`)
    this.subject.next(event);
  }
}

export class EmitEvent {
  constructor(public name: any, public value?: any, public actionType? :any, public isDataCapture? :boolean) { }

}

export enum MyApprovalEvents {
  ApproveeChanged,
  ExpenseStatusChanged,
  GloableSpinnerVisiable
}

export enum ExpenseActionEvents {
  Approve,
  Escalate,
  Onhold,
  followUp
}