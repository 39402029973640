import { Component, OnInit, Inject, OnDestroy, SkipSelf, Optional } from '@angular/core';
import { IAuthService } from './core/services/user/Iauth.service';
import { environment } from 'src/environments/environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus,SilentRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { EventBusService, MyApprovalEvents } from './core/services/event-bus/event-bus.service';
import { UserService } from './core/services/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MyaConfirmationPopupComponent } from './mya-controls/mya-confirmation-popup/mya-confirmation-popup.component';
import jsonFile from '../../package.json';
import { Title } from '@angular/platform-browser';
import { InteractionRequiredAuthError } from '@azure/msal-common'
import { AppConfigService } from './core/services/app-config/app-config.service';
import { TimeReportClient } from './core/services/clientapi/timereport-client';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'MyApprovalsNext v' + jsonFile.version;
  loginDisplay = false;
  isGlobalSpinnerVisible= false
  privacyPopupOpened = false;
  hideElement:boolean = true;
  private readonly _destroying$ = new Subject<void>();
  private appConfigData: any;

  constructor(
  @Inject('AuthService') private auth: IAuthService,
  @Optional() private broadcastService: MsalBroadcastService,
  private router: Router,
  private eventbus: EventBusService,
  private userService: UserService,
  private timeReportApi: TimeReportClient,
  private privacyStatementDialog: MatDialog,
  private titleService: Title,
  @Optional() private msalService: MsalService,
  private appConfigService: AppConfigService) {
    this.appConfigData = this.appConfigService.getAppConfig;
    this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe(data => {
        let event = data as NavigationStart;
        if (event.url == "/id_token") {
          console.log(window.location.hash);
          this.router.navigate([]);
        }
        if(event?.url?.indexOf('recieptEnlargedView') != -1){
          this.hideElement = false;
        }
      })
  }

  public ngOnInit(): void {
    if (environment.production) {
      this.broadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          console.log(result);
        });
      this.broadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.loginDisplay = this.auth.identityClaims;
          if (this.loginDisplay) this.auth.setActiveUser();
          this.privacyStatementPopupHandler();
          this.getTokenRedirect(this.msalService.instance.getAllAccounts()[0]);
        })
    }
    else {
      this.auth.setConfig();
      setTimeout(
        () => { this.loginDisplay = true; }
        , 0)
    }
    this.router.events.pipe().subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationEnd ) {
       this.setTitle(this.title);
      }
    });
    if (!environment.production) {
      this.privacyStatementPopupHandler();
    }
    this.initializeEvents();
  }

  private getTokenRedirect(account: any): void {
    var requestManual:SilentRequest = {
      scopes: this.appConfigData.AADv2Eso.protectedResourceMap.find((element: { usage: string; }) => { return element.usage == "next"; }).scopes,
    };
    requestManual.account = account;
    this.msalService.instance.acquireTokenSilent(requestManual).then(result => { 
      sessionStorage.setItem('ddrumsdk.idtoken', result.idToken);
    })
    .catch((error: any) => {
      if (error instanceof InteractionRequiredAuthError) {
        this.msalService.acquireTokenRedirect(requestManual);
      } else {
        console.error(error);
      }
    });
  }

  private initializeEvents(): void {
    this.router.events.pipe().subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart ) {
        this.isGlobalSpinnerVisible = true;
      }

      if (
        routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError) {
        this.isGlobalSpinnerVisible = false;
      }
    });

    this.eventbus.on(MyApprovalEvents.GloableSpinnerVisiable)
      .subscribe(res =>
        this.isGlobalSpinnerVisible = res.value
      );
  }

  privacyStatementPopupHandler() {
    const key = this.userService.getLoginUser().peopleKey;
    const loginUserEid = this.userService.getLoginUser().enterpriseId;
    if (key == null || key == undefined) {
      return;
    }
    this.timeReportApi.getPrivacyConfiguration('MyAprovals', loginUserEid).subscribe(status => {
      if (!status) {
        this.privacyPopupOpened = true;
        let dialogRef = this.privacyStatementDialog.open(MyaConfirmationPopupComponent, {
          height: '418px',
          width: '650px',
          disableClose: true,
          closeOnNavigation: false
        });
        dialogRef.componentInstance.displayPrivacyStatementPopup = true;
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'privacy-statement-closed') {
            this.timeReportApi.savePrivacyConfiguration('MyAprovals',loginUserEid).subscribe((res) => {
              if (res == null) {
                this.privacyPopupOpened = false;
              }
            });
          }
        });
      }
    });

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }
}
