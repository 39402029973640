import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { take, catchError } from 'rxjs/operators';
import { Observable,throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import jsonFile from '../../../../../package.json';
@Injectable({
  providedIn: 'root'
})

export class AppConfigService {

  private appConfig: any;
  private static readonly appConfigPath = environment.name === 'Mock' 
    ? 'assets/data/app-config/appconfig.mock.json' 
    : 'assets/data/app-config/appconfig.json';
  private static readonly isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  private static readonly keyConfig = 'myAConfig';
  private static readonly versionConfig = jsonFile.version;
  constructor(private httpClient: HttpClient, private handler: HttpBackend) { }
  
  public get getAppConfig(): any { return this.appConfig; }

  loadAppConfig() {
    return new Promise((resolve, reject) => {
      if (sessionStorage[AppConfigService.keyConfig]) {
        this.appConfig = JSON.parse(sessionStorage[AppConfigService.keyConfig]);
        if (environment.production) {
          this.initAppConfig();
        }
        resolve(true);
        return;
      }
      this.httpClient = new HttpClient(this.handler);
      let versionConfig = AppConfigService.appConfigPath +`?v=${AppConfigService.versionConfig}`;
      this.httpClient
        .get(versionConfig).pipe(take(1), catchError(err => throwError(()=> err || 'Server error')))
        .subscribe((configResponse: any) => {
          sessionStorage[AppConfigService.keyConfig] = JSON.stringify(configResponse);
          this.appConfig = configResponse;
          if (environment.production){
            this.initAppConfig();
          }
          resolve(true);
        });
    });
  }

  private initAppConfig(){
    let protectedResourceMap = new Map<string, string[]>();
    this.appConfig.AADv2Eso.protectedResourceMap.forEach((resource:any) => {
      protectedResourceMap.set(resource.url, resource.scopes);
    });

    this.appConfig.AADv2Eso.MSALInstanceFactory.cache.storeAuthStateInCookie = AppConfigService.isIE;
    this.appConfig.AADv2Eso.MSALInstanceFactory.system.loggerOptions = {loggerCallback: (logLevel:any, message: string) => {console.log(message)}, piiLoggingEnabled: false, logLevel: 0};
    this.appConfig.AADv2Eso.MSALInterceptorConfigFactory.protectedResourceMap = protectedResourceMap;
  }
}
