import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageContentComponent } from './mya-root/components/page-content/page-content.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from './core/auth/auth.guard';
import { environment } from 'src/environments/environment';
import { Msal2Guard } from './core/auth/msal2.guard';
import { AppRoutingResolver } from './app-routing.resolver';

const routes: Routes = [
   {// Needed for hash routing
    path: 'code',
    redirectTo: ''
  },
  {
    path: '',
    component: PageContentComponent,
    resolve: {
      coutires: AppRoutingResolver
    },
    runGuardsAndResolvers: 'always',
    canActivate: [environment.production ? Msal2Guard : AuthGuard],
    children: [
      {
        path: 'expense',
        loadChildren: ()=> import('./mya-expense/expense.module').then(e => e.ExpenseModule)
      },
    ], 
  },
  {
    path: 'recieptEnlargedView/:imageSrc',
    runGuardsAndResolvers: 'always',
    canActivate: [environment.production ? Msal2Guard : AuthGuard],
    loadChildren: () =>     import('./mya-expense/components/expense-view/expense-view-receipts/expense-view-receipts-enlarged-view/expense-view-receipts-enlarged-view.module')
      .then(m => m.ExpenseViewReceiptsEnlargedViewModule)
  },
  {
    path: 'settingsnotification',
    runGuardsAndResolvers: 'always',
    canActivate: [environment.production ? Msal2Guard : AuthGuard],
    loadChildren: () =>     import('./mya-notificationSettings/notification-settings.module')
      .then(m => m.NotificationsettingsModule)
  }
];
const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
  onSameUrlNavigation: 'reload',
  useHash: true,
  enableTracing: false,
  initialNavigation: isIframe ?  'disabled' : undefined
});

