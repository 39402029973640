import { NgModule, ApplicationRef, DoBootstrap, APP_INITIALIZER, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule, AppRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageContentComponent } from './mya-root/components/page-content/page-content.component';
import { PageFooterComponent } from './mya-root/components/page-footer/page-footer.component';
import { PageHeaderComponent } from './mya-root/components/page-header/page-header.component';
import { ApproveeModule } from './mya-approvee/approvee.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { AppRoutingResolver } from './app-routing.resolver';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MyAControlsModule} from './mya-controls/myacontrols.module';
import { AppConfigService } from './core/services/app-config/app-config.service';
import { environment } from 'src/environments/environment';
import { FakeBackendInterceptor, fakeBackendProvider } from './core/interceptors/mock-Interceptor/fack-backend.interceptor';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { Configuration, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}
export function MSALInstanceFactory(): IPublicClientApplication {
  const isIE: boolean = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  const appConfigMSAL: any = inject(AppConfigService).getAppConfig.AADv2Eso;
  const msalInstanceFactory: Configuration = appConfigMSAL.MSALInstanceFactory;
  msalInstanceFactory.cache.storeAuthStateInCookie = isIE;
  msalInstanceFactory.system.loggerOptions = { 
    loggerCallback,
    logLevel: LogLevel.Error,
    piiLoggingEnabled: false
  }
  return new PublicClientApplication(msalInstanceFactory);
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const appConfigMSAL: any = inject(AppConfigService).getAppConfig.AADv2Eso;
  const msalGuardConfiguration: MsalGuardConfiguration = appConfigMSAL.MSALGuardConfigFactory;
  return msalGuardConfiguration;
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const appConfigMSAL: any = inject(AppConfigService).getAppConfig.AADv2Eso;
  const msalInterceptorConfiguration: MsalInterceptorConfiguration = appConfigMSAL.MSALInterceptorConfigFactory;
  const protectedResourceMap = new Map<string, Array<string>>();
  appConfigMSAL.protectedResourceMap.forEach((resource: { url: string, scopes: [] }) => {
    protectedResourceMap.set(resource.url, resource.scopes);
  });
  msalInterceptorConfiguration.protectedResourceMap = protectedResourceMap;
  return msalInterceptorConfiguration;
}

@NgModule({
  declarations: [
    AppComponent,
    PageContentComponent,
    PageFooterComponent,
    PageHeaderComponent
  ],
  imports: [
    AppRoutes,
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    ApproveeModule,
    HttpClientModule,
    MyAControlsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MsalModule
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [
    AppRoutingResolver,
    AppConfigService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: environment.production ? MsalInterceptor : FakeBackendInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    MsalService,
    environment.name == 'Mock' ? fakeBackendProvider : []
  ]
})
// export class AppModule implements DoBootstrap {
//   ngDoBootstrap(ref: ApplicationRef) {
//     console.log("Bootstrap: App");
//     ref.bootstrap(AppComponent);
//   }
// }

export class AppModule {
  
}
