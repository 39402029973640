import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-mya-confirmation-popup',
  templateUrl: './mya-confirmation-popup.component.html',
  styleUrls: ['./mya-confirmation-popup.component.sass']
})
export class MyaConfirmationPopupComponent implements OnInit, OnDestroy {

  displayPrivacyStatementPopup: boolean = false;
  displayTermsOfUsePopup: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.displayPrivacyStatementPopup || this.displayTermsOfUsePopup){
      this.displayPrivacyStatementPopup = false;
      this.displayTermsOfUsePopup = false;
    }

  }

}
