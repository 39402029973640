import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, OnDestroy } from '@angular/core';
import { EventBusService, EmitEvent, MyApprovalEvents, ExpenseActionEvents } from 'src/app/core/services/event-bus/event-bus.service';
import { Expense } from '../../../shared/models/expense/expense';
import { PeoplePicker } from 'src/app/shared/models/people-picker';
import { Subscription } from 'rxjs';
import { PeoplePickerService } from '../../services/people-picker.service';
import { filter } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config/app-config.service';

@Component({
  selector: 'app-approvee-list',
  templateUrl: './approvee-list.component.html',
  styleUrls: ['./approvee-list.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApproveeListComponent implements OnInit, OnDestroy {
  @Input() public currentSelectedUser: string;
  @Input() setFilteredCountryKey: string[];
  @Input() public isDataCapture: boolean;
  @Output() lookingForNewUserEvent: EventEmitter<string> = new EventEmitter();
  @Output() currentSelectedUserChangedEvent: EventEmitter<string> = new EventEmitter();
  private appConfigData: any;
  showSpinner: boolean = false;
  expense: Expense;
  allpeoplePickerResult: PeoplePicker[] = null;
  peoplePickerResult: PeoplePicker[] = null;
  peopleListSubscription: Subscription;
  peopleSelectedColor: string = 'color: white; background-color: rgb(117, 0, 192) !important';
  constructor(private eventBus: EventBusService, private peoplePickerService: PeoplePickerService, private changeDetectorRef: ChangeDetectorRef,
    private appConfigService: AppConfigService) {
    this.appConfigData = this.appConfigService.getAppConfig
  }

  ngOnInit() {
    this.peopleListSubscription = this.peoplePickerService.getPeoplePickerList.subscribe(peopleList => {
      this.allpeoplePickerResult = this.peoplePickerResult = peopleList;
      this.peoplePickerResult.map(a => {
        this.peoplePickerService.getUserProfile(a.enterpriseId)
          .pipe(filter(val => val !== null))
          .subscribe(res => {
            a.profilePicture = res;
            this.changeDetectorRef.markForCheck();
          });
      })
    })
    this.eventBus.on(MyApprovalEvents.ExpenseStatusChanged).subscribe(res => {
      this.initializeApproveeList(res.actionType, res.value.length, res.value.shouldReloadApproveeForApprover);
    });
    this.showSpinner = false;
  }

  selectUser(user: any) {
    this.currentSelectedUser = user.enterpriseId;
    this.eventBus.dispatch(new EmitEvent(MyApprovalEvents.ApproveeChanged, { approvee: user.enterpriseId, countryKey: user.countryKey, companyInformation: user.companyInformation }, null, this.isDataCapture));
    this.currentSelectedUserChangedEvent.emit(this.currentSelectedUser);
  }

  initializeApproveeList(actionType: any, selectedRowsLength: any, shouldReloadApproveeForApprover: any) {
    let currentSelectedUser = this.peoplePickerService.currentSeletedPeople(this.currentSelectedUser);
    if (actionType == ExpenseActionEvents.Approve
      || actionType == ExpenseActionEvents.Escalate
      || actionType == ExpenseActionEvents.followUp) {
      this.isDataCapture ? currentSelectedUser.expenseCountDataCapture -= selectedRowsLength
        : currentSelectedUser.expenseCount -= selectedRowsLength;
      this.peoplePickerService.updatePeopleList(currentSelectedUser, this.isDataCapture);
      if (shouldReloadApproveeForApprover) {
        this.lookingForNewUserEvent.emit();
      }
    }
    else if (actionType == ExpenseActionEvents.Onhold) {
      this.lookingForNewUserEvent.emit();
    }
    this.changeDetectorRef.detectChanges();
  }

  markSelectedApprovee() {
    let elements = document.querySelectorAll(".userCard");
    if (!elements || elements.length == 0) return;
    Array.from(elements).forEach((e: any) =>
      e.style.cssText = e.style.cssText.replace(this.peopleSelectedColor, ''))
    let firstApprovee = document.getElementsByClassName('userCard')[0];
    firstApprovee.setAttribute("style", this.peopleSelectedColor)
  }

  openReviewLink(user: any) {
    let shouldRedirectToMyteNext = this.appConfigData.ShouldRedirectToMyteNext;
    let url = "";
    if (shouldRedirectToMyteNext) {
      let periodEnd = encodeURIComponent(btoa(user.currentPeriod));
      let viewMode = encodeURIComponent(btoa("Supervisor"));
      let eid = encodeURIComponent(btoa(user.enterpriseId));
      url = this.appConfigData.MyteNextUrl + "?periodEnd=" + periodEnd + "&viewMode=" + viewMode + "&subordinate=" + eid
    } else {
      url = this.appConfigData.MyTeUrl + "?periodEnd=" + user.currentPeriod + "&action=Review&reviewee=" + user.enterpriseId
        + "&Country=" + user.countryKey;
    }
    window.open(url);
  }

  ngOnDestroy(): void {
    this.peopleListSubscription.unsubscribe();
  }

  trackFunc = (index: number, item: any) => {
    return index;
  }

}
