import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export interface ILogService {
}

@Injectable({
    providedIn: 'root'
  })

export class LogService implements ILogService {
    constructor(private toastr: ToastrService) {}

    showSuccess(message: string, title?: string ){
        this.toastr.success(message, title)
    }

    showWarning(message: string, title?: string ){
        this.toastr.warning(message, title)
    }

    showError(message: string, title?: string ){
        this.toastr.error(message, title)
    }

    showInfo(message: string, title?: string){
        this.toastr.info(message, title);
    }

    logError() {
        this.toastr.error();
        console.error();
    }
    
    logWarning() {
        this.toastr.warning();
        console.warn();
    }

    
}