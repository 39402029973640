import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { filter, map } from 'rxjs/operators';
import { AppConfigService } from '../app-config/app-config.service';
import { IAuthService } from '../user/Iauth.service';
@Injectable({ providedIn: 'root' })
export class Oauth2Service implements Partial<IAuthService> {
  private appconfig: any
  constructor(
    private oauthService: OAuthService, private appConfigService: AppConfigService
  ) {
    this.appconfig = this.appConfigService.getAppConfig.AdfsEso;
    // Useful for debugging:
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        switch (event.type) {
          case 'token_expires':
          case  'session_changed':
          break;
        }
      } 
    });
  }

  public handleLoginAuthectication(): boolean {
    if (this.accessToken && this.identityClaims){
      return true;
    }
    else {
        this.oauthService.clearHashAfterLogin = true;
        this.oauthService.initImplicitFlow();
    }
    return false;
  }

  public setConfig(): void {
    this.oauthService.configure(this.appconfig);
    this.setupAutomaticRefresh();
    this.oauthService.tryLogin({});
}

private setupAutomaticRefresh() {
  this.oauthService.silentRefreshRedirectUri = this.appconfig.silentRefreshRedirectUri;
  this.oauthService.setupAutomaticSilentRefresh();
}

  public logout(): void{ this.oauthService.logOut(); }
  public refresh(): void { this.oauthService.silentRefresh(); }
  public hasValidToken(): boolean { return this.oauthService.hasValidAccessToken(); }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken(): string { return this.oauthService.getAccessToken(); }
  public get identityClaims(): object { return this.oauthService.getIdentityClaims(); }
  public get idToken(): string { return this.oauthService.getIdToken(); }
  public get logoutUrl(): any { return this.oauthService.logoutUrl; }
}