import { NgModule, Optional, SkipSelf, ModuleWithProviders, APP_INITIALIZER, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportGurad } from './import.gurad';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FakeBackendInterceptor } from './interceptors/mock-Interceptor/fack-backend.interceptor';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { AuthGuard } from '../core/auth/auth.guard';
import { Oauth2Service } from './services/login/oauth2.service';
import { UserService } from './services/user/user.service';
import { MsalModule, MsalInterceptor, MsalService, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalGuard, MsalBroadcastService, MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { Msal2Service } from './services/login/msal2.service';
import { Msal2Guard } from './auth/msal2.guard';
import { AppConfigService } from './services/app-config/app-config.service';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MyApprovalApiInjector } from './mya-api-injector';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    environment.production
      ? MsalModule
      : OAuthModule.forRoot(),
  ],
  providers: [
    {
      provide: 'AuthService',
      useFactory: () => {
        if (!environment.production) {
          return new Oauth2Service(inject(OAuthService), inject(AppConfigService));
        }
        else {
          return new Msal2Service(inject(MsalService));
        }
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: environment.production ? MsalInterceptor : FakeBackendInterceptor,
      multi: true
    },
    environment.production ? [Msal2Guard, MsalGuard] : AuthGuard,
  ]
})

export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: environment.production ? [
        {
          provide: MSAL_INSTANCE,
          useFactory: () => new PublicClientApplication(MyApprovalApiInjector.getAppConfigData(inject(AppConfigService)).AADv2Eso.MSALInstanceFactory as Configuration)
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: () => MyApprovalApiInjector.getAppConfigData(inject(AppConfigService)).AADv2Eso.MSALGuardConfigFactory as MsalGuardConfiguration
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: () => MyApprovalApiInjector.getAppConfigData(inject(AppConfigService)).AADv2Eso.MSALInterceptorConfigFactory as MsalInterceptorConfiguration
        },
        MsalService,
        MsalBroadcastService,
      ] : []
    }
  }
}

