import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ClickInside]'
})
export class ClickInsideDirective {
  @Input() ClickInside = '';

  constructor(private elementRef: ElementRef) { 
  }
  @HostListener('document:click',['$event.target'])
  public onClick(targetElement: any) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    
    if (clickedInside && !targetElement.__ng_removed) {
      let elements = document.getElementsByClassName(this.elementRef.nativeElement.className);
      this.backToDefaultStyle(elements);
      this.elementRef.nativeElement.style.cssText += this.ClickInside;

    }
  }

  private backToDefaultStyle(elements: any) {
    Array.from(elements).forEach((e: any) =>
      e.style.cssText = e.style.cssText.replace(this.ClickInside, '')
    ) 
  }
}