import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PayableExpenseMetadataDto } from 'src/app/core/services/clientapi/expenseapproval-client';
import { EmitEvent, EventBusService, MyApprovalEvents } from '../../../core/services/event-bus/event-bus.service';
import CoverageCountry from '../../../shared/models/coverage-country';
import { ApproveesService } from '../../services/approvees.service';
import { MyaGlobalCacheService } from '../../../core/services/cache/mya-global-cache.service'
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { PeoplePickerResult } from 'src/app/core/services/clientapi/timereport-client';
import { PeoplePickerService } from '../../services/people-picker.service';
import { IPeopleSearchResult, PeoplePicker } from 'src/app/shared/models/people-picker';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../../core/services/user/user.service';
import { ApproveeListComponent } from '../approvee-list/approvee-list.component';

@Component({
  selector: 'app-approvee-category',
  templateUrl: './approvee-category.component.html',
  styleUrls: ['./approvee-category.component.sass'],
})
export class ApproveeCategoryComponent implements OnInit {
  @ViewChild('input') input: ElementRef;
  @ViewChild('approveeListComponent') approveeListComponent: ApproveeListComponent;
  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement>;
  @ViewChild('dropdown') dropdownmenu: ElementRef;
  faSearch = faSearch;
  coverageCountryList!: CoverageCountry[];
  peoplePickerList: Observable<PeoplePickerResult[]>;
  isDropDownOptionsOpen: boolean = false;
  approveeList: PayableExpenseMetadataDto[] = [];
  showSpinner: boolean = false;
  countryOptions: Observable<string[]>;
  myControl = new UntypedFormControl();
  currentSelectedApprovee: string;
  showNormalExpenseList = true;
  userRole: string;
  filteredcountrykey: string;
  selectedCountries: string[] = [];
  showdiv: boolean = false;
  peopleSearchResult: IPeopleSearchResult[] = [];
  showSearchSpinner: boolean = false;
  isDataCapture: boolean = false;
  requestDataEntryList: PayableExpenseMetadataDto[] = [];
  showSearchdiv: boolean = true;

  constructor(private route: ActivatedRoute, private eventBus: EventBusService,
    private approveesService: ApproveesService, private myaGlobalCacheService: MyaGlobalCacheService,
    private peoplePickerService: PeoplePickerService, private router: Router,
    private userservice: UserService) {
    this.countryOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  ngOnInit(): void {
    this.showSpinner = true;
    this.coverageCountryList = this.route.snapshot.data.coutires;
    this.coverageCountryList.filter(x => x.isShow = true);
    this.userRole = this.myaGlobalCacheService.getGlobalCache<string>('UserRole') ?? '';
    this.mapGroupByUserRoleName(this.userRole);
    this.approveesService.getPayableExpenseMetadata(this.userRole, false)
      .subscribe(res => {
        if (res && res.length > 0) {
          this.approveeList = res;
          this.peoplePickerService.peoplePiker_initializer(this.approveeList);
          this.currentSelectedApprovee = this.approveeList[0].approvee;
          this.initializeEvents();
          this.router.navigate(['/expense']);

        }
        this.showSpinner = false;
      })
  }

  private initializeEvents(): void {
    this.router.events.pipe(
      filter((routerEvent: any)=> routerEvent instanceof NavigationEnd 
      && this.router.url.includes("expense")))
      .subscribe(() => {
      {
        this.eventBus.dispatch(new EmitEvent(MyApprovalEvents.ApproveeChanged, this.approveeList[0]));
      }
    })
  }
  
  public mapGroupByUserRoleName(userrole: string): void {
    this.showSearchdiv = (userrole as string).includes('NonMD')
      || (userrole as string).includes('MD')
      || (userrole as string).includes('AFS')
      || (userrole as string).includes('AuditLead')
      || (userrole as string).includes('Vip')
      ? true : false
  }

  private _filter(value: string): string[] {
    const countires = this.coverageCountryList.map(option => { return option.countryName });
    if (!value) return countires;
    const filterValue = value.toLowerCase();
    return countires.filter(option => option.toLowerCase().includes(filterValue));
  }


  public hideOptions(event: any): void {
    this. clearSearchBox();
    this.showdiv = false;
    if (!this.isDropDownOptionsOpen) {
      return;
    }
    this.isDropDownOptionsOpen = false;
    if (event) {
      event.stopPropagation();
    }
  }

  RadioButtonChange(isDataCapture: boolean) {
    this.isDataCapture = isDataCapture;
    if (isDataCapture) {
      if (!this.requestDataEntryList || this.requestDataEntryList.length == 0) {
        this.eventBus.dispatch(new EmitEvent(MyApprovalEvents.GloableSpinnerVisiable, true));
        this.approveesService.getPayableExpenseMetadata(this.userRole, isDataCapture)
          .subscribe(res => {
            this.requestDataEntryList = res;
            this.refreshPeopleList(res);
            this.eventBus.dispatch(new EmitEvent(MyApprovalEvents.GloableSpinnerVisiable, false));
          })
      }
      else {
        this.refreshPeopleList(this.requestDataEntryList);
      }
    }
    else {
      this.refreshPeopleList(this.approveeList);
    }
    this.approveeListComponent?.markSelectedApprovee();
  }

  refreshPeopleList(peopleList: PayableExpenseMetadataDto[]) {
    let selectedPeople = peopleList && peopleList.length > 0 ? peopleList[0] : null;
    this.currentSelectedApprovee = selectedPeople != null ? peopleList[0].approvee : null;
    if (peopleList) {
      this.peoplePickerService.peoplePiker_initializer(peopleList);
      this.eventBus.dispatch(new EmitEvent(MyApprovalEvents.ApproveeChanged, selectedPeople, null, this.isDataCapture));
    }
  }

  optionSelected(country: string) {
    if (this.selectedCountries.includes(country)) {
      this.removeCountry(country);
    } else {
      this.selectedCountries.push(country);
      this.dropdownmenu.nativeElement.style.top = "208px";
    }
    this.selectedCountries = JSON.parse(JSON.stringify(this.selectedCountries));
    this.clearSearchBox();
    this.countryInput.nativeElement.focus();
  }

  onPeopleSelected(enterpriseId: any) {
    let approvee = this.peoplePickerService["dataStore"].selectedPeople.find(val => val.enterpriseId == enterpriseId);
    this.showSpinner = true;
    this.approveesService.getUserPayableExpenseMetadata(enterpriseId, this.userRole, false)
      .pipe(filter(val => val !== null))
      .subscribe(res => {
        this.addAprovee(res[0]);
        let people = this.peoplePickerService.mapToPeoplePicker(res)[0];
        if (res && approvee != undefined) {
          this.myaGlobalCacheService.clearCacheByKey("ExpenseList" + this.userservice.getLoginUser().enterpriseId + "_" + this.isDataCapture + "_" + enterpriseId);
          this.peoplePickerService.updatePeopleList(people, this.isDataCapture);
          this.addAprovee((this.isDataCapture ? this.requestDataEntryList : this.approveeList).find(val => val.approvee == enterpriseId))[0] = res[0];
          this.peoplePickerService.sortPeople(enterpriseId);
        }
        else {
          this.peoplePickerService.addPeople(people);
        }
        this.updateSelectedPeople(people);
        this.showSpinner = false;
      })
  }

  // Method to add the selected approvee to lists.
  addAprovee(selectedUser: PayableExpenseMetadataDto) {
    this.addApproveeToList(this.approveeList, selectedUser);
    this.addApproveeToList(this.requestDataEntryList, selectedUser);
    return this.isDataCapture ? this.requestDataEntryList : this.approveeList;
  }

  // Method to add the selected approve to the top of list.
  addApproveeToList(list: PayableExpenseMetadataDto[], selectedUser: PayableExpenseMetadataDto) {
    if (!list) return
    const index = list.findIndex(val => val.approvee == selectedUser.approvee);
    list.unshift((index >= 0) ? list.splice(index, 1)[0] : selectedUser);
  }

  updateSelectedPeople(people: PeoplePicker) {
    this.currentSelectedApprovee = people.enterpriseId;
    this.eventBus.dispatch(new EmitEvent(MyApprovalEvents.ApproveeChanged, { approvee: people.enterpriseId, countryKey: people.countryKey, companyInformation: people.companyInformation }, null, this.isDataCapture));
    if (this.router.url != '/expense') {
      this.router.navigate(['/expense'])
    }
    this.input.nativeElement.value = ''
    this.peopleSearchResult = [];
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(searchText =>
        searchText.trim() == '' ? this.peopleSearchResult = [] : this.loadData(searchText)
      )
    );

  loadData(searchText: string): Observable<any> {
    this.showSearchSpinner = true;
    return this.approveesService.peoplePickerSearch(searchText).pipe(
      map((p: any) => {
        this.peopleSearchResult = this.peoplePickerService.mapPeopleSearchData(p.splice(0, 10));
        this.showSearchSpinner = false;
        this.peopleSearchResult.forEach((peopleSearch: IPeopleSearchResult) => {
          this.peoplePickerService.getUserProfile(peopleSearch.enterpriseId)
            .pipe(filter(val => val !== null))
            .subscribe((userprofile: any) => {
              peopleSearch.profilePictureData = userprofile.data;
            });
        })
      })
    )
  }

  clearInput() {
    this.showSearchSpinner = false;
    this.input.nativeElement.value = '';
    this.peopleSearchResult = [];
  }


  removeCountry(country: string): void {
    this.selectedCountries = this.selectedCountries.filter(value => value !== country);
    if(this.selectedCountries.length === 0)
        this.dropdownmenu.nativeElement.style.top = "190px";
  }

  clearAllCountries(): void {
    this.selectedCountries = [];
    this.dropdownmenu.nativeElement.style.top = "190px";
    this.clearSearchBox();
  }

  clearSearchBox(): void {
    this.countryInput.nativeElement.value = '';
    this.myControl.setValue(null);
  }

  filterInput(event: any) {
    var regex = new RegExp("^[a-zA-Z0-9.\\-_]+$");
    if (!regex.test(event.data)) {
      this.input.nativeElement.value = this.input.nativeElement.value.replace(event.data, "");
    }
  }
  
  trackFunc = (index: number, item: any) => {
    return index;
  }

  formatter = (result: PeoplePickerResult) => result.enterpriseId || "";

  inputFormatter = (result: PeoplePickerResult) => result.enterpriseId || "";

  lookingForNewUser() {
    this.showSpinner = true;
    this.approveesService.getPayableExpenseMetadata(this.userRole, this.isDataCapture)
      .subscribe(res => {
        if (res) {
          if (this.isDataCapture) {
            this.requestDataEntryList = res;
          }
          else {
            this.approveeList = res;
          }
          this.refreshPeopleList(res);
        }
        this.showSpinner = false;
      })
  }

  currentSelectedUserChanged(event: string){
    this.currentSelectedApprovee = event;
  }
}
