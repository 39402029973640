<mat-toolbar color="primary" class="demo-app-header">
  <mat-toolbar-row>
    <img src="assets/image/logo.png" alt="" class="logo">
    <span class="myApproval_title">myApprovals</span>
    <div class="menu-button">
      <button mat-icon-button style="margin-right: 5px;" aria-label="Settings" [routerLink]="['settingsnotification']" target="_self">
        <mat-icon>settings</mat-icon>
      </button>

      <button mat-icon-button (click)= "onHomeIconClick()" aria-label="Home" style="margin-right: 10px;" ng-href="#" target="_self">
        <mat-icon>home</mat-icon>
      </button>

      <button mat-fab style="background-color: lightgray;" aria-label="My profile" (click)="backToHome()"><img [src]="loginUserProfilePicture" alt=""
          class="img userProfile"></button>

      <button title="Logout" mat-icon-button class= "logoutbtn" *ngIf= "environment === 'Local'" (click)= "logOut()">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>