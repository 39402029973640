import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

const CACHE_SIZE = 1;

export interface IStorageCache<T> {
  data: T;
  expireDatetime: Date;
}

export interface IObservableCache<T> {
  dataValue: Observable<T>;
  expireDatetime: Date
}

@Injectable({
  providedIn: 'root'
})

export abstract class BaseCacheService {

  //cache strategy for http request observable
  private cache: Map<string, IObservableCache<any>> = new Map<string, IObservableCache<any>>();

  protected keyExists<T>(key: string): Observable<T> | undefined {
    if (this.cache && this.cache.has(key))
    {
       let valueInCache = this.cache.get(key) as IObservableCache<T>;
       if (valueInCache.expireDatetime.valueOf() <= new Date().valueOf()){
         this.cache.delete(key);
         return undefined;
       }
        return valueInCache?.dataValue;
    }
     return undefined;     
  }

  protected setHttpResponse<T>(key: string, data: Observable<T>): IObservableCache<T> {
   
      let cacheValue : IObservableCache<T> = {
        dataValue : data.pipe(shareReplay(CACHE_SIZE)),
        expireDatetime : new Date(new Date().getTime() + (5 * 60000))
      }
      this.cache.set(key, cacheValue);
      return cacheValue;
  }

  protected clearAllCache(): void{
    this.cache.clear();
  }

  protected clearCacheByKey(key: string): void {
    if (this.keyExists(key))
      this.cache.delete(key);
  }

  //cache strategy for localStorage / sessionStorage
  protected isInLocalStorage<T>(key: string): IStorageCache<T> | null {
    let value = localStorage.getItem(key);
    if (value) {
      let dataInCache = JSON.parse(value) as IStorageCache<T>;
      if (dataInCache.expireDatetime.valueOf() <= new Date().valueOf()) {
        this.removeLocalStorageByKey(key);
      } else {
        return dataInCache;
      }
    }
    return null;
  }

  protected saveInLocalStorage<T>(key: string, storageCache: IStorageCache<T>) : void {
    localStorage.setItem(key, JSON.stringify(storageCache));
  }

  protected removeLocalStorageByKey(key: string){
    localStorage.removeItem(key);
  }

}
