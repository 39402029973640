import {inject, InjectionToken} from '@angular/core';
import { AppConfigService } from './services/app-config/app-config.service';


export class MyApprovalApiInjector {

    private static appConfigData: any = null;
    static getAppConfigData(appConfigService: AppConfigService) {
        if (this.appConfigData === null)
        this.appConfigData = appConfigService.getAppConfig;
        return this.appConfigData;
    }
}

export const EXPENSEAPPROVAL = new InjectionToken<string>('EXPENSEAPPROVAL_URL', {
    providedIn: 'root',
    factory: () => MyApprovalApiInjector.getAppConfigData(inject(AppConfigService)).EXPENSEAPPROVAL_NEXT_URL
});



export const TIMEREPORT = new InjectionToken<string>('TIMEREPORT_URL', {
    providedIn: 'root',
    factory: () => MyApprovalApiInjector.getAppConfigData(inject(AppConfigService)).TIMEREPORT_URL
});
