import { NgModule, Optional,SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApproveeListComponent } from './components/approvee-list/approvee-list.component';
import { ApproveeCategoryComponent } from './components/approvee-category/approvee-category.component';
import { MaterialModule } from '../mya-controls/material/material.module';
import { RouterModule } from '@angular/router';
import { MyAControlsModule } from '../mya-controls/myacontrols.module';
import  { ApproveesService } from './services/approvees.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PeoplePickerService } from './services/people-picker.service';
import { FilterApproveePipe } from '../shared/pipes/filter-approvee.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    ApproveeListComponent,
    ApproveeCategoryComponent,
    FilterApproveePipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    MyAControlsModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule
  ],
  exports: [
    ApproveeCategoryComponent
  ],
  providers: [
    ApproveesService,
    PeoplePickerService
  ]
})
export class ApproveeModule { }
